import { Suspense, useEffect, useRef, useState } from "react";
import { JeelizThreeFiberHelper } from "../helpers/JeelizThreeFiberHelper";
import { JEELIZFACEFILTER, NN_4EXPR } from "facefilter";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import Inner from "./Inner";
import { useDaily } from "@daily-co/daily-react";
import DailyCall from "./daily/DailyCall";
import useFilterDaily from "../hooks/useFilterDaily";

let _threeFiber: any = null;
const ThreeGrabber = (props: any) => {
  _threeFiber = useThree();
  useFrame(
    JeelizThreeFiberHelper.update_camera.bind(
      null,
      props.sizing,
      _threeFiber.camera
    )
  );
  return null;
};

const compute_sizing = () => {
  // compute  size of the canvas:

  return { width: 350, height: 200, top: 0, left: 10 };
};

const _maxFacesDetected = 1; // max number of detected faces
const _faceFollowers = new Array(_maxFacesDetected);
let _expressions = null;

const FaceFollower = (props: any) => {
  // This reference will give us direct access to the mesh
  const objRef = useRef<any>();
  useEffect(() => {
    const threeObject3D = objRef.current;
    _faceFollowers[props.faceIndex] = threeObject3D;
  });

  const mouthOpenRef = useRef();
  const mouthSmileRef = useRef();
  // useFrame(() => {
  //   if (mouthOpenRef.current) {
  //     const s0 = props.expression.mouthOpen;
  //     mouthOpenRef.current.scale.set(s0, 1, s0);
  //   }

  //   if (mouthSmileRef.current) {
  //     const s1 = props.expression.mouthSmile;
  //     mouthSmileRef.current.scale.set(s1, 1, s1);
  //   }
  // });

  console.log("RENDER FaceFollower component");

  return (
    <object3D ref={objRef}>
      <Suspense fallback={null}>
        <Inner />
      </Suspense>
    </object3D>
  );
};

function Main() {
  const [isInit, setIsInit] = useState(false);
  const [sizing, setSizing] = useState(compute_sizing());
  const faceFilterCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [started, setStarted] = useState(false);
  const threeCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const daily = useDaily();
  const filterDaily = useFilterDaily();

  let _timerResize: any = null;
  const handle_resize = () => {
    // do not resize too often:
    if (_timerResize) {
      clearTimeout(_timerResize);
    }
    _timerResize = setTimeout(do_resize, 200);
  };

  const do_resize = () => {
    _timerResize = null;
    const newSizing = compute_sizing();
    setSizing(newSizing);
  };

  useEffect(() => {
    if (isInit) return;
    if (!faceFilterCanvasRef.current) return;

    const callbackDetect = (faceIndex: any, isDetected: any) => {
      console.log("Executed");
      if (isDetected) {
        console.log("DETECTED");
      } else {
        console.log("LOST");
      }
    };

    window.addEventListener("resize", handle_resize);
    window.addEventListener("orientationchange", handle_resize);
    JEELIZFACEFILTER.init({
      canvas: faceFilterCanvasRef.current,
      NNC: NN_4EXPR,
      callbackReady: function (errCode: any, spec: any) {
        if (errCode) {
          console.log("AN ERROR HAPPENS. ERROR CODE =", errCode);
          return;
        }
        // [init scene with spec...]
        console.log("INFO: JEELIZFACEFILTER IS READY");
        setIsInit(true);
        JeelizThreeFiberHelper.init(spec, _faceFollowers, callbackDetect);
      }, //end callbackReady()

      // called at each render iteration (drawing loop)
      callbackTrack: function (detectStatesArg: any) {
        // Render your scene here
        // [... do something with detectState]
        const detectStates =
          detectStatesArg.length > 0 ? detectStatesArg : [detectStatesArg];

        JeelizThreeFiberHelper.update(detectStates, _threeFiber.camera);

        // render the video texture on the faceFilter canvas:
        JEELIZFACEFILTER.render_video();
      }, //end callbackTrack()
    });
  }, [isInit, threeCanvasRef]);

  useEffect(() => {});

  return (
    <div style={{ background: "green", minHeight: "100vh", width: "100vw" }}>
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          alignItems: "center",
          width: "100vw",
          gap: "200px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
          }}
        >
          <div
            style={{
              height: sizing.height,
              width: sizing.width,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Canvas
              ref={threeCanvasRef}
              gl={{
                preserveDrawingBuffer: true,
              }}
              style={{
                position: "absolute",
                zIndex: 2,
                ...sizing,
              }}
            >
              <perspectiveCamera>
                <ThreeGrabber sizing={sizing} />
                <FaceFollower sizing={sizing} faceIndex={0} />
              </perspectiveCamera>
            </Canvas>

            <canvas
              ref={faceFilterCanvasRef}
              style={{
                position: "absolute",
                zIndex: 1,
                ...sizing,
              }}
              width={sizing.width}
              height={sizing.height}
              // width="600"
              // height="600"
            ></canvas>
          </div>
          <button
            disabled={started}
            onClick={async () => {
              if (threeCanvasRef.current && faceFilterCanvasRef.current) {
                // prepare our merger canvas
                const canvas: any = faceFilterCanvasRef.current.cloneNode();
                const ctx = canvas.getContext("2d");
                ctx.fillStyle = "#FFF";
                const toMerge = [
                  faceFilterCanvasRef.current,
                  threeCanvasRef.current,
                ];
                const anim = () => {
                  ctx.fillRect(0, 0, canvas.width, canvas.height);
                  toMerge.forEach((layer) => ctx.drawImage(layer, 0, 0));
                  requestAnimationFrame(anim);
                };
                anim();

                const audioTrack = await navigator.mediaDevices.getUserMedia({
                  audio: true,
                  video: false,
                });
                const stream = canvas.captureStream();
                daily?.setInputDevicesAsync({
                  videoSource: stream.getVideoTracks()[0],
                });
                daily?.setBandwidth({
                  trackConstraints: {
                    width: sizing.width,
                    height: sizing.height,
                  },
                });

                stream.addTrack(audioTrack.getAudioTracks()[0]);
                setStarted(true);
              }
            }}
          >
            {started ? "Started" : "Start"}
          </button>
          {filterDaily?.roomIdentifier && (
            <div className="text-lg">
              Room Identifier {filterDaily.roomIdentifier}
            </div>
          )}
        </div>

        <DailyCall />
      </div>
    </div>
  );
}

export default Main;
