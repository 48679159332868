import DailyIframe, { DailyCall } from "@daily-co/daily-js";
import { DailyProvider } from "@daily-co/daily-react";
import React, { useEffect, useState } from "react";
import DailyCoContext from "../../contexts/DailyCoContext";

const DAILYCO_TOKEN = process.env.REACT_APP_DAILYCO_TOKEN;
const DAILYCO_DOMAIN = process.env.REACT_APP_DAILYCO_DOMAIN;

function DailyCoCustomProvider({ children }: { children: React.ReactNode }) {
  const [url, setURL] = useState("");
  const [roomType, setRoomType] = useState<"create" | "join" | "">("");
  const [callObject, setCallObject] = useState<DailyCall | null>(null);
  const [joiningCall, setJoiningCall] = useState(false);

  useEffect(() => {
    if (roomType !== "create") return;
    console.log("Executed this");
    async function createRoom() {
      const exp = Math.round(Date.now() / 1000) + 60 * 30;
      const options = {
        properties: {
          exp,
        },
      };
      const response = await fetch("https://api.daily.co/v1/rooms", {
        method: "POST",
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + DAILYCO_TOKEN,
        },
      });
      const res = await response.json();

      setURL(res.url);
    }
    createRoom();
  }, [roomType]);

  useEffect(() => {
    if (!url) return;
    setJoiningCall(true);
    const call = DailyIframe.createCallObject({ url, startAudioOff: false });
    call
      .join()
      .then((success) => {
        setCallObject(call);
        setJoiningCall(false);
      })
      .catch((err) => {
        console.log("Room joining failed", err);
        setJoiningCall(false);
      });
  }, [url]);

  function getIdentifier(fullURL: string) {
    const urlArr = fullURL.split("/");
    return urlArr[urlArr.length - 1];
  }

  return (
    <DailyCoContext.Provider
      value={{
        changeRoomType: (room: "create" | "join" | "") => setRoomType(room),
        joiningCall,
        roomType,
        changeRoomIdentifier: (roomURL: string) =>
          setURL(`https://${DAILYCO_DOMAIN}/${roomURL}`),
        joinedCall: callObject !== null,
        roomIdentifier: url ? getIdentifier(url) : "",
      }}
    >
      {callObject ? (
        <DailyProvider callObject={callObject}>{children}</DailyProvider>
      ) : (
        <>{children}</>
      )}
    </DailyCoContext.Provider>
  );
}

export default DailyCoCustomProvider;
