import { useLoader } from "@react-three/fiber";
import React from "react";
import SQLogo from "./SQLogo_Vector.png";
import SingingWarrior from "./models/SingingWarrior";
import { TextureLoader } from "three";

export default function Inner() {
  const sqLogo = useLoader(TextureLoader, SQLogo);

  return (
    <mesh name="mainCube">
      <ambientLight intensity={1} />
      <directionalLight intensity={1} />
      <planeGeometry args={[2.5, 1]} />
      {/* <planeBufferGeometry args={[2.5, 1]} /> */}
      <SingingWarrior />
      <meshBasicMaterial map={sqLogo} transparent />
    </mesh>
  );
}
