import React from "react";
import "./App.css";
import DailyCoCustomProvider from "./components/daily/DailyCoCustomProvider";
import FilterDailyCall from "./components/FilterDailyCall";
// import TensorFlowTryout from "./components/TensorFlowTryout";

function App() {
  return (
    <div className="App">
      <DailyCoCustomProvider>
        <FilterDailyCall />
      </DailyCoCustomProvider>
      {/* <TensorFlowTryout /> */}
      {/* <AnimationsTryout /> */}
    </div>
  );
}

export default App;
