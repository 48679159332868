import React from "react";

export default React.createContext<{
  changeRoomType: (room: "create" | "join" | "") => void;
  joiningCall: boolean;
  roomType: "create" | "join" | "";
  changeRoomIdentifier: (roomURL: string) => void;
  joinedCall: boolean;
  roomIdentifier: string;
} | null>(null);
