import {
  DailyAudio,
  DailyVideo,
  useDaily,
  useLocalParticipant,
  useParticipantIds,
  useVideoTrack,
} from "@daily-co/daily-react";
import React, { useEffect, useRef } from "react";

function DailyCall() {
  const daily = useDaily();
  const local = useLocalParticipant();
  const videoTrack = useVideoTrack(local?.session_id || "");
  const localVideoRef = useRef<HTMLVideoElement | null>(null);

  const participants = useParticipantIds({ filter: "remote" });

  useEffect(() => {
    console.log("Participants", participants);
    if (!localVideoRef.current) return;
    if (!videoTrack.persistentTrack) return;
    localVideoRef.current.srcObject = new MediaStream([
      videoTrack.persistentTrack,
    ]);
  }, [daily, participants, videoTrack.persistentTrack]);

  return (
    <div className="flex flex-wrap gap-3">
      {participants.map((participant) => {
        return (
          <div key={participant}>
            <DailyVideo
              style={{ width: 200 }}
              type="video"
              key={participant}
              sessionId={participant}
            />
          </div>
        );
      })}
      <DailyAudio />
    </div>
  );
}

export default DailyCall;
