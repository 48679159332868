import React, { useState } from "react";
import Main from "./Main";
import useFilterDaily from "../hooks/useFilterDaily";

function FilterDailyCall() {
  const faceFilterDaily = useFilterDaily();
  const [roomIdentifier, setRoomIdentifier] = useState("");
  if (!faceFilterDaily) return <span className="text-lg">Loading</span>;

  return (
    <>
      {!faceFilterDaily.joinedCall ? (
        <div
          style={{
            width: "100vw",
            minHeight: "100vh",
            backgroundColor: "green",
          }}
        >
          <div className="flex flex-col gap-2 p-4 items-center">
            {!faceFilterDaily.roomType ? (
              <>
                <div className="text-lg">Select an option</div>
                <button
                  className="bg-slate-600 rounded-sm text-sm text-slate-200"
                  onClick={() => faceFilterDaily.changeRoomType("create")}
                >
                  Create and Join a room
                </button>
                <button
                  className="bg-slate-600 rounded-sm text-sm text-slate-200"
                  onClick={() => faceFilterDaily.changeRoomType("join")}
                >
                  Join a specific room
                </button>
              </>
            ) : (
              <>
                {faceFilterDaily.roomType === "create" ? (
                  <div className="text-lg">Creating a room</div>
                ) : (
                  <div className="flex flex-col">
                    <div className="text-lg">Enter the room name</div>
                    <input
                      value={roomIdentifier}
                      onChange={(e) => setRoomIdentifier(e.target.value)}
                      type="text"
                    />
                    <button
                      disabled={!roomIdentifier || faceFilterDaily.joiningCall}
                      onClick={() =>
                        faceFilterDaily.changeRoomIdentifier(roomIdentifier)
                      }
                    >
                      {faceFilterDaily.joiningCall ? "Joining" : "Join"}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <Main />
      )}
    </>
  );
}

export default FilterDailyCall;
