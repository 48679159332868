/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 singing_warrior.glb 
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function SingingWarrior(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/warrior.glb");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions.sing.play();
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.mixamorigHips} />
          <pointLight
            intensity={10}
            position={[100, 50, 100]}
            rotation={[-Math.PI / 2, 0, 0]}
          />
          <ambientLight intensity={10} />

          <skinnedMesh
            name="Paladin_J_Nordstrom"
            geometry={nodes.Paladin_J_Nordstrom.geometry}
            material={materials["Paladin_MAT.002"]}
            skeleton={nodes.Paladin_J_Nordstrom.skeleton}
          />
          <skinnedMesh
            name="Paladin_J_Nordstrom_Helmet"
            geometry={nodes.Paladin_J_Nordstrom_Helmet.geometry}
            material={materials["Paladin_MAT.002"]}
            skeleton={nodes.Paladin_J_Nordstrom_Helmet.skeleton}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/singing_warrior.glb");
